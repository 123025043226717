<template>
  <b-card>
    <b-button class="btn-close" variant="flat-secondary" size="lg" @click="$emit('closed')">
      &times;</b-button>
    <b-form class="mt-0">
      <b-form-row>
        <b-form inline>
          <b-form-group label="Role Name">
            <b-form-input id="role-name-field" v-model="selectedRole.name" :disabled="!isNewRole" />
          </b-form-group>
        </b-form>
      </b-form-row>
      <b-form-row class="mt-1">
        <b-col class="col-md-6">
          <b-form-group label="Permission Level">
            <b-form-radio-group
              id="radio-group-2"
              v-model="selectedRole.permissionLevel"
              name="radio-sub-component"
              stacked
            >
              <ol>
                <li>
                  <b-form-radio id="limited-checkbox"
                                v-model="selectedRole.permissionLevel"
                                v-b-tooltip.hover.top="'Node ID only will be returned from queries, with no other properties, and is not writable'"
                                class="mr-2"
                                value="limited"
                  >
                    Limited Read Only
                    <feather-icon :icon="icons['limited']" />
                  </b-form-radio>
                </li>
                <li>
                  <b-form-radio id="read-checkbox"
                                v-b-tooltip.hover.top="'All properties can be viewed, but are not writable'"
                                class="mr-2"
                                value="r"
                  >
                    Read Only
                    <feather-icon :icon="icons['r']" />
                  </b-form-radio>
                </li>
                <li>
                  <b-form-radio id="readWrite-checkbox"
                                v-model="selectedRole.permissionLevel"
                                v-b-tooltip.hover.top="'All properties can be viewed or edited'"
                                class="mr-2"
                                value="rw"
                  >
                    Read-Write
                    <feather-icon :icon="icons['rw']" />
                  </b-form-radio>
                </li>
                <li>
                  <b-form-radio id="deny-checkbox"
                                v-model="selectedRole.permissionLevel"
                                v-b-tooltip.hover.top="'Node will not be returned from queries, and is not writable'"
                                class="mr-2"
                                value="deny"
                  >
                    Hidden
                    <feather-icon :icon="icons['deny']" />
                  </b-form-radio>
                </li>
              </ol>
              <span class="hidden">{{ neo4jPermissions }}</span>
              <b-alert show variant="info"><feather-icon icon="'InfoIcon'" />Note: The above list is in order of precedence.
                This means that if a node such as a Requirement is assigned many permissions, the highest permission will override others.
                For example, the Hidden permission will always override other permissions.
              </b-alert>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col v-if="!isNewRole" class="col-md-6">
          <b-form-group label="Users with Role">
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="user-search"
                v-model="allUserSearchText"
                class="form-control-merge"
                name="user-search"
                placeholder="Search users"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                />
              </b-input-group-append>
            </b-input-group>
            <b-form-select v-model="selected" readonly :options="roleUsers" :value-field="'id'" :text-field="'username'" multiple :select-size="8" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-1">
        <b-button v-if="isNewRole" variant="primary" @click="saveNewRole">
          Create</b-button>
        <b-button v-if="!isNewRole" variant="primary" @click="saveEditedRole">
          Save</b-button>
        <b-button v-if="!isNewRole" class="ml-1" variant="outline-danger" @click="deleteRole">
          Delete Role</b-button>
      </b-form-row>
    </b-form>
  </b-card>
</template>

<script>
import { icons } from '@/components/Permissions/Permissions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AdminRole',
  props: {
    role: { type: Object, default: null },
    allUsers: { type: Array, default: null },
  },
  data() {
    return {
      selectedRole: this.role,
      selected: [''],
      options: [],
      roleUsers: [],
      allUserSearchText: '',
      allRolesSearchText: '',
      neo4jPermissions: '',
      icons,
    }
  },
  computed: {
    isNewRole() {
      return this.selectedRole.id === 'new'
    },
  },
  async mounted() {
    if (this.selectedRole.id !== 'new') {
      const r = await this.$coreService.roleManagementApi.getRole(this.selectedRole.name)
      this.selectedRole.permissionLevel = r?.neo4j_privileges ?? 0
      this.roleUsers = r?.users
    }
  },
  methods: {
    async deleteRole() {
      const opts = {
        centered: true,
        okVariant: 'danger',
      }
      const response = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the group ${this.selectedRole.name}?`, opts)
      if (response) {
        await this.$coreService.roleManagementApi.deleteRole(this.selectedRole.name)
        this.selectedRole = ''
        await this.$store.dispatch('auth/getAllRoles')
      }
    },
    async saveNewRole() {
      try {
        const response = await this.$coreService.roleManagementApi.createRole(this.selectedRole.name, this.selectedRole.permissionLevel)
        this.selectedRole = { ...this.selectedRole, response }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error Saving Role',
            text: e.message.includes('409') ? 'Role already exists' : 'Unknown error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      await this.$store.dispatch('auth/getAllRoles')
    },
    async saveEditedRole() {
      try {
        await this.$coreService.roleManagementApi.updateRole(this.selectedRole.name, this.selectedRole.permissionLevel)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error Saving Role',
            text: e.message.includes('409') ? 'Role already exists' : 'Unknown error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped>
.btn-col {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.btn-close {
    margin: 0.2em 0.5em 0 0;
    padding: 0.2em;
    position: absolute;
    right: 0;
    top: 0;
    font-size: xx-large;
    z-index: 9999;
}
</style>
