<template>
  <div>
    <b-card>
      <b-button variant="flat-secondary" @click="addRoleClicked">
        <feather-icon icon="PlusIcon" class="text-success" />
        Create Role
      </b-button>
      <b-table
        :items="roles"
        :fields="roleFields"
        :sort-by.sync="roleSortBy"
        :sort-desc.sync="roleSortDesc"
        responsive="sm"
        selectable
        select-mode="single"
        selected-variant="dark"
        :current-page="currentRolePage"
        :per-page="perPage"
        @row-selected="onRoleRowSelected"
      />
      <b-pagination
        v-model="currentRolePage"
        :total-rows="roleRowCount"
        :per-page="perPage"
      />
    </b-card>
    <div v-if="selectedRole">
      <admin-role :key="selectedRole.id" :role="selectedRole" @closed="closeRole" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdminRole from './AdminRoleEdit.vue'

const scrollToEnd = () => {
  setTimeout(() => window.scrollTo(9999, 9999), 100)
}

export default {
  name: 'AdminRolesTable',
  components: {
    AdminRole,
  },
  data() {
    return {
      roleFields: [{ key: 'name', label: 'Roles', sortable: true }],
      roleSortBy: 'name',
      roleSortDesc: false,
      selectedRole: null,
      currentRolePage: 1,
      perPage: 8,
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/allRoles',
    }),
    roleRowCount() {
      return this.roles.length
    },
  },
  async mounted() {
    await this.$store.dispatch('auth/getAllRoles')
  },
  methods: {
    closeRole() {
      this.selectedRole = null
    },
    addRoleClicked() {
      this.selectedRole = { id: 'new' }
      scrollToEnd()
    },
    onRoleRowSelected(items) {
      this.selectedRole = null
      if (items.length > 0) {
        [this.selectedRole] = items
        scrollToEnd()
      }
    },
  },
}
</script>
